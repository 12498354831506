import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import AuthContext from '../context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginPage from './LoginPage';
import { config } from '../utilities/constants/config';

const BackofficePage = ({ children }) => {
  const { authState } = useContext(AuthContext),
    [loggedIn, setLoggedIn] = useState(authState.authTokenSession ?? false);
  let wrapperClass = 'wrapper';
  if (
    location.hostname.includes('localhost') ||
        location.hostname.includes('staging')
  ) {
    wrapperClass = 'wrapper-warning';
  }

  useEffect(() => {
    if (authState.authTokenSession) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [authState.authTokenSession]);

  return (
    <>
      { !loggedIn &&
      <GoogleOAuthProvider
        clientId={ config.clientId }
        onScriptLoadError={ providerResponse => {
          console.log(providerResponse);
        } }
      >
        <LoginPage />
      </GoogleOAuthProvider>
      }
      { loggedIn &&
    <div className={ wrapperClass } >
      <main id='main'>
        <section>
          <div className='global-container'>
            { children }
          </div>
        </section>
      </main>
    </div>
      }
    </>
  );
};

BackofficePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default BackofficePage;
