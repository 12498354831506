import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './i18n';
import { BaseProvider, createTheme } from 'baseui';
import TagManager from 'react-gtm-module';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import selectraPrimitives from './theme/themePrimitives';
import selectraOverrides from './theme/themeOverrides';

import Home from './pages/Home';

import BackofficePage from './hoc/BackofficePage';
import { AuthProvider } from './containers/Auth/Components/AuthProvider';

import gtmConfig from './utilities/gtmConfig';
import routesConfig from './utilities/routes/config';

const
  container = document.getElementById('home-page'),
  selectraTheme = createTheme(selectraPrimitives, selectraOverrides),
  styletronEngine = new Styletron,
  root = createRoot(container);

if (container) {
  const tagManagerArgs = {
    gtmId: gtmConfig('fr')
  };

  TagManager.initialize(tagManagerArgs);

  root.render(
    <StyletronProvider value={ styletronEngine }>
      <BaseProvider theme={ selectraTheme }>
        <BrowserRouter>
          <AuthProvider>
            <BackofficePage>
              <Routes>
                <Route path={ routesConfig('home') } element={ <Home /> } />
                <Route path='' element={ <Navigate to={ routesConfig('home') } /> } />
                <Route path='login' element={ <Navigate to={ routesConfig('home') } /> } />
              </Routes>
            </BackofficePage>
          </AuthProvider>
        </BrowserRouter>
      </BaseProvider>
    </StyletronProvider>
  );
}
