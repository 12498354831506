import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { config } from '../utilities/constants/config';
import AuthContext from '../context/AuthContext';

const useLogin = () => {
  const
    [loginError, setLoginError] = useState(null),
    [loginLoading, setLoginLoading] = useState(false),
    authContext = useContext(AuthContext),
    queryParameters = new URLSearchParams(window.location.search),
    navigate = useNavigate(),
    validateEmail = (email) => {
      return email.match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    validate = (email, password) => {
      return validateEmail(email) && password !== '';
    },
    addMinutes = (date, minutes) => {
      return new Date(date.getTime() + minutes * 60000);
    },
    saveSession = (result) => {
      const authData = {
        authTokenSession: result.data.csrfToken,
        modulesStatuses: result.data.modulesStatusesEnergy,
        menuRoutes: result.data.menuRoutes,
        loaderRoutes: result.data.loaderPaths,
        isSelectraAdmin: result.data.isSelectraAdmin,
        isAdmin: result.data.isAdmin,
        isExternal: result.data.isExternal,
        isMcpAgent: result.data.isMcpAgent,
        userId: result.data.id,
        sessionExpiration: addMinutes(new Date(), config.minutesSessionTime),
      };
      authContext.authDispatch({
        type: 'updateState',
        payload: {
          ...authData,
          initialized: true
        }
      });
      localStorage.setItem(config.auth, JSON.stringify(authData));
      if (queryParameters.get('from')) {
        navigate(queryParameters.get('from'));
      } else {
        navigate('/admin');
      }
    },
    googleLogin = async(accessToken) => {
      setLoginLoading(true);
      try {
        const request = await fetch(
            window.location.origin + '/login-google',
            {
              method: 'post',
              headers: {
                'Accept': 'Application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
              },
              body: JSON.stringify({
                accessToken: accessToken,
              })
            }),
          response = await request.json();
        if (request.status === 200) {
          saveSession(response);
        } else {
          setLoginError(response.errorMessage);
        }
      } catch (error) {
        setLoginError(error);
      }
      setLoginLoading(false);
    },
    hopLogin = async(email, password) => {
      setLoginLoading(true);
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      try {
        const request = await fetch(
            window.location.origin + '/login',
            {
              method: 'post',
              headers: {
                'Accept': 'Application/json',
                'X-Requested-With': 'XMLHttpRequest',
              },
              body: formData
            }),
          response = await request.json();
        if (request.status === 200) {
          saveSession(response);
        } else {
          setLoginError(response.errorMessage);
        }
      } catch (error) {
        setLoginError(error);
      }
      setLoginLoading(false);
    };

  return {
    validate,
    hopLogin,
    googleLogin,
    loginError,
    loginLoading
  };

};

export default useLogin;
