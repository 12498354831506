const authActions = (state, action) => {
  if (action.type === 'updateState') {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default authActions;
