export const
  SDInputOverrides = () => ({
    Root: {
      style: ({
        $theme,
        $isFocused,
        $disabled,
        $error,
      }) => {
        const
          borderColor = $disabled ?
            $theme.colors.mono300 :
            $error ?
              $theme.colors.negative500 :
              $theme.colors.mono300,
          outlineColor = $disabled ?
            $theme.colors.mono300 :
            $error ?
              $theme.colors.negative500 :
              $theme.colors.primary500,
          textColor = $disabled ?
            $theme.colors.mono600 :
            $theme.colors.mono700,
          bgColor = $disabled ?
            $theme.colors.mono100 :
            $theme.colors.white;

        return {
          height: '2.5rem',

          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,

          borderTopWidth: '0.0625rem',
          borderRightWidth: '0.0625rem',
          borderBottomWidth: '0.0625rem',
          borderLeftWidth: '0.0625rem',

          outline: ( $disabled || !( $isFocused || $error )) ?
            'none' :
            `0.125rem solid ${ outlineColor }`,
          outlineOffset: ( $isFocused || $error ) ? '-.125rem' : 0,

          color: textColor,

          borderTopColor: borderColor,
          borderRightColor: borderColor,
          borderBottomColor: borderColor,
          borderLeftColor: borderColor,

          backgroundColor: bgColor,
        };
      },
    },
    Input: {
      style: ({ $theme, $disabled }) => ({
        paddingTop: '.5rem',
        paddingRight: '.5rem',
        paddingBottom: '.5rem',
        paddingLeft: '.5rem',

        fontSize: '.875rem',
        lineHeight: '140%',

        backgroundColor: $disabled ?
          $theme.colors.mono100 :
          $theme.colors.white,
      })
    },
    EndEnhancer: {
      style: ({
        $theme,
        $disabled,
      }) => {
        const
          textColor = $disabled ?
            $theme.colors.mono600 :
            $theme.colors.mono700;

        return {
          paddingTop: 'auto',
          paddingRight: '.75rem',
          paddingBottom: 'auto',
          paddingLeft: '.75rem',
          backgroundColor: 'none',
          color: textColor,
        };
      },
    },
    StartEnhancer: {
      style: ({
        $theme,
        $disabled,
      }) => {
        const textColor = $disabled ?
          $theme.colors.mono600 :
          $theme.colors.mono700;

        return {
          padding: 'auto .75rem',
          backgroundColor: 'none',
          color: textColor,
        };
      },
    }
  });
