const useToken = () => {
  const
    get = (name) => {
      return localStorage.getItem(name);
    },
    set = (name, tokenValue) => {
      localStorage.setItem(name, tokenValue);
      return true;
    },
    destroy = () => {
      localStorage.removeItem('hop-auth');
    };
  return {
    get,
    set,
    destroy
  };

};

export default useToken;
