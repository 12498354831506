import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Card } from 'baseui/card';
import { SIZE } from 'baseui/input';
import SDButton from '../SDComponents/SDButton/SDButton';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'baseui/icon';
import { KIND } from 'baseui/button';
import LogoHOP from '../icons/LogoHOP';
import { useStyletron } from 'baseui';
import useLogin from '../hooks/useLogin';
import { Notification } from 'baseui/notification';
import SDInput from '../SDComponents/SDInput/SDInput';
import { FormControl } from 'baseui/form-control';

const LoginPage = () => {
  const
    { t } = useTranslation(),
    [css, theme] = useStyletron(),
    [email, setEmail] = useState(''),
    [emailError, setEmailError] = useState(''),
    [password, setPassword] = useState(''),
    {
      hopLogin,
      validate: loginValidate,
      loginLoading,
      loginError
    } = useLogin(),
    loginWrapper = css({
      color: theme.colors.accent700,
      background: 'linear-gradient(116.82deg, #4793DD 0%, #004791 100%)',
      height: '100vh',
    }),
    loginCard = css({
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
    }),
    logo = css({
      justifyContent: 'center',
      display: 'flex',
    }),
    loginHop = async() => {
      if (loginValidate(email, password)) {
        await hopLogin(email, password);
      } else {
        setEmailError('Invalid email or password');
      }
    };

  return (
    <div className={ loginWrapper } >
      <main id='main'>
        <section>
          <div className='global-container'>
            <Card className={ loginCard }
              overrides={ { Root: { style: { display: 'flex', width: '328px', borderRadius: '1.5rem' } } } }
            >
              <div className={ logo } >
                <LogoHOP size={ 50 } />
              </div>

              <Notification>
                L'accès à HOP depuis https://hop.selectra.io est limité à tous les agents. Pour réaliser des ventes vous devez vous rendre sur <a href="https://app.hop.selectra.io/">https://app.hop.selectra.io</a>
              </Notification>

              <FormControl
                label={ 'Admin ' + t('auth:email') + ' (No sales agents allowed)' }
                error={ emailError }
              >
                <SDInput
                  id='login-input-email'
                  size={ SIZE.compact }
                  onChange={ e => {
                    setEmail(e.target.value);
                  } }
                />
              </FormControl>

              <FormControl
                label={ t('auth:password') }
              >
                <SDInput
                  id='login-input-password'
                  size={ SIZE.compact }
                  type='password'
                  onChange={ e => {
                    setPassword(e.target.value);
                  } }
                />
              </FormControl>

              {
                loginError &&
                <Notification kind={ KIND.negative }>
                  { loginError }
                </Notification>
              }

              <SDButton
                id='login-normal-button'
                isLoading={ loginLoading }
                kind={ KIND.secondary }
                size={ SIZE.default }
                block={ true }
                endEnhancer={ () => <ChevronRight size={ 24 } /> }
                onClick={ loginHop }
              >
                { t('auth:connect') }
              </SDButton>

            </Card>
          </div>
        </section>
      </main>
    </div>
  );
};

LoginPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default LoginPage;
