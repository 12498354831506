import common from './common.json';
import home from './home.json';
import menu from './menu.json';
import auth from './auth.json';

export default {
  home,
  common,
  menu,
  auth,
};
