import React, { useContext, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import AuthContext from '../context/AuthContext';
import authData from '../store/authData';
import useToken from '../hooks/useToken';

import { Notification } from 'baseui/notification';

const Home = () => {
  const [data, setData] = useState(null),
    [css] = useStyletron(),
    { authState, authDispatch } = useContext(AuthContext),
    tokenTool = useToken(),
    initUrl = authState.loaderRoutes['home'] ?? '',
    cardStyle = css({
      padding: '1rem',
      marginBottom: '2rem',
      backgroundColor: 'white',
      borderRadius: '1rem',
      boxShadow: '0 2px 6px rgba(20,96,170,.3)',
    }),
    parent = css({
      display: 'flex',
    }),
    child = css({
      flex: 1,
      width: '50%',
      margin: '0.2rem',
    }),

    initialize = () => {
      fetch(
        initUrl,
        {
          headers: {
            'Accept': 'Application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          method: 'GET',
        }
      ).then(async(response) => {
        if (response.status === 401) {
          const data = await response.json(),
            errorMessage = data.message || 'Erreur: vous tentez d\'accéder à Hop sur une IP non autorisée. Si ce message s\'affiche alors qu\'il ne le devrait pas, demandez l\'aide de votre manager.';
          alert(errorMessage);
          tokenTool.destroy();
          authDispatch({
            type: 'updateState',
            payload: {
              ...authData,
              initialized: true
            }
          });
          return;
        }
        response.json().then((json) => {
          setData(json);
        });
      });
    };

  useEffect(() => {
    initialize();
  }, []);

  if (!data) {
    return <div className={ cardStyle }><p>Fetching data...</p></div>;
  }

  return (
    <>
      <div className={ parent }>
        <div className={ cardStyle + child }>
          <Notification>
            L'accès à HOP depuis https://hop.selectra.io est limité à tous les agents. Pour réaliser des ventes vous devez vous rendre sur <a href='https://app.hop.selectra.io/'>https://app.hop.selectra.io</a>
          </Notification>
        </div>
      </div>
    </>
  );
};

export default Home;
