export const
  SDButtonOverrides = ( block ) => {
    return {
      BaseButton: {
        style: ({ $theme }) => ({
          width: block ? '100%' : 'auto',

          borderTopLeftRadius: $theme.borders.radius200,
          borderTopRightRadius: $theme.borders.radius200,
          borderBottomRightRadius: $theme.borders.radius200,
          borderBottomLeftRadius: $theme.borders.radius200,

          fontFamily: $theme.typography.font550.fontFamily,
          fontWeight: $theme.typography.font550.fontWeight,
        }),
      },
    };
  };
