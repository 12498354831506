const AUTH = {
    clientId: '672817155381-oq8d83l0fe62opin49dg7p4cnrqjftj6.apps.googleusercontent.com',
    auth: 'hop-auth',
    minutesSessionTime: 720,
  },
  STATUS = {};

function getConfig() {
  return { ...AUTH, ...STATUS };
}

// eslint-disable-next-line one-var
export const config = getConfig();
