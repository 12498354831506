import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'baseui/input';
import { mergeOverrides } from 'baseui';

import { SDInputOverrides } from './styled-components';


const SDInput = ({
  overrides,
  ...rest
}) => {
  const newOverrides = mergeOverrides(
    SDInputOverrides(),
    overrides
  );

  return (
    <Input
      { ...rest }
      overrides={ newOverrides }
    />
  );
};

SDInput.propTypes = {
  overrides: PropTypes.object,
};

export default SDInput;
